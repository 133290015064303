import * as React from 'react'
import styled from 'styled-components'

type Props = {
  error: Error
}

const Container = styled.div`
  margin-top: 18px;
  margin-left: 20px;
`

const Fallback = (props: Props) => {
  const { error } = props

  return (
    <Container>
      <div>An error has occured</div>
      {error && (
        <div>
          <div>{error.toString()}</div>
        </div>
      )}
    </Container>
  )
}

export default Fallback

import React from 'react'

// eslint-disable-next-line
import { BUGSNAG_API_KEY, GOOGLE_TAGJS_ID } from './src/config'

// Initialize bugsnag before other imports
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
const bugsnagClient = bugsnag({
  apiKey: BUGSNAG_API_KEY,
  notifyReleaseStages: ['production'],
  releaseStage: process.env.NODE_ENV,
})
bugsnagClient.use(bugsnagReact, React)

import 'modernizr'
import { includes } from 'ramda'
import 'scroll-behavior-polyfill'
import { detect } from 'detect-browser'
import Fallback from './src/components/Fallback/Fallback'
// Polyfill URL and URLSearchParam
import 'url-polyfill'

import 'normalize.css'
import './src/styles/global.css'

if (typeof window !== 'undefined') {
  require('intersection-observer')
}

export const onRouteUpdate = ({ location }) => {
  // Call gtag pageview on route update
  if (window.gtag) {
    setTimeout(() => {
      window.gtag('config', GOOGLE_TAGJS_ID, {
        anonymize_ip: true,
        page_path: location.pathname,
        page_title: document.title,
      })
    }, 100)
  }
}

// Always scroll to top
export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0)
  return false
}

export const wrapRootElement = ({ element }) => {
  const ErrorBoundary = bugsnagClient.getPlugin('react')
  return <ErrorBoundary FallbackComponent={Fallback}>{element}</ErrorBoundary>
}

// Only log cats on modern browsers (sorry IE + edge)
const browser = detect()
if (includes(browser.name, ['chrome', 'firefox', 'safari'])) {
  console.log(
    `%c
                       A___A
           A___A       |o o|
     ____ / o o \\      |='=|
___/~____   ='= /_____/    |___
  (______)__m_m_)    /  ||||
                    |___||||]
`,
    'font-family: Monaco, monospace'
  )
}
